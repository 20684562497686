module.exports = {
  COMPANYNAME: 'BABA TRAVELS AND TOURISM',
  COMPANYEMAIL: 'support@babaair.com',
  COMAPNYPHONE: '+91 9849787154',
  COMPANYCITY: 'HYDERABAD',
  COMPANYGST: '36ANVPM2246A1Z0',
  CURRENCY: 'INR',
  COMPANYADDRESS:'7 TH FLOOR, 5-9-58/1-15 , FLAT- 708-G, BABU KHAN ESTATES, Basheer Bagh, Hyderabad, Hyderabad, Telangana,500001',
  //RAZORPAY_SECRET:'iPcqI8cJj0nVQAPfEdzJqnb3',//Test
  //RAZORPAY_KEY_ID:'rzp_test_HAF6HCcH7moc64',//Test
  //RAZORPAY_SECRET: 'OcdZYVFl9q3KLN3xJxpDinJY',//Prod
  //RAZORPAY_KEY_ID: 'rzp_live_3UGYtxuG07auJU',//Prod
  APILOCALURL: 'https://localhost:7086/api/',
  APISERVERURL: 'https://babaairapi.azurewebsites.net/api/',
  APIACTIVATEURL: 'https://babaairapi.azurewebsites.net/api/',

  LOGINUSER: 'home/login',
  REGISTERUSER: 'home/registeruser',
  CHECKEMAIL: 'home/check-email',
  CONFIRMEMAIL: 'home/confirmemail',
  SENDRESETPASSWORD: 'home/sendresetlink',
  RESETPASSWORD: 'home/resetpassword',
  GETPROMOCODES:'promocode',
  GETPAYMENTGATEWAYS:'Payment',

  REGISTERAGENT: 'home/agent-register',
  LOGINAGENT: 'home/agent-login',
  GETAGENTBYID: 'agent/GetByAgent',
  UPDATEAGENT: 'Agent',

  GETUSERBYID: 'User/GetById',
  UPDATEUSER: 'User',
  CHANGEPASSWORD: 'User/ChangePassword',

  GETALLSTUDYCONTENTS: 'StudyContent/GetAll',
  GETSTUDYCONTENTS: 'StudyContent',
  CREATESTUDYCONTENT: 'StudyContent/Create',
  UPDATESTUDYCONTENT: 'StudyContent',
  DELETESTUDYCONTENT: 'StudyContent',
  GETSTUDYCONTENTBYID: 'StudyContent/GetById',
  GETSTUDYCONTENTBYDESTINATIONID: 'StudyContent/GetByDestinationId',

  GETALLSTUDYDESTINATIONS: 'StudyDestination/GetAll',
  GETSTUDYDESTINATIONS: 'StudyDestination',
  CREATESTUDYDESTINATION: 'StudyDestination/Create',
  UPDATESTUDYDESTINATION: 'StudyDestination',
  DELETESTUDYDESTINATION: 'StudyDestination',
  GETSTUDYDESTINATIONBYID: 'StudyDestination',


  GETALLSUPPLIERS: 'Supplier/GetAll',
  GETSUPPLIERS: 'Supplier',
  CREATESUPPLIER: 'Supplier/Create',
  UPDATESUPPLIER: 'Supplier',
  DELETESUPPLIER: 'Supplier',
  GETSUPPLIERBYID: 'Supplier/GetById',

  GETALLSUPPLIERCONTACTS: 'SupplierContact/GetAll',
  GETSUPPLIERCONTACTS: 'SupplierContact',
  CREATESUPPLIERCONTACT: 'SupplierContact/Create',
  UPDATESUPPLIERCONTACT: 'SupplierContact',
  DELETESUPPLIERCONTACT: 'SupplierContact',
  GETSUPPLIERCONTACTBYSUPPLIERID: 'SupplierContact/GetById',
  GETBYSUPPLIERID: 'SupplierContact/GetById',

  SEARCHHOLIDAYDESTINATIONS: 'Destination/Search',
  GETALLHOLIDAYDESTINATIONS: 'Destination/GetAll',
  GETHOLIDAYDESTINATIONS: 'Destination',
  CREATEHOLIDAYDESTINATION: 'Destination/Create',
  UPDATEHOLIDAYDESTINATION: 'Destination',
  DELETEHOLIDAYDESTINATION: 'Destination',
  GETHOLIDAYDESTINATIONBYID: 'Destination',

  GETALLCUSTOMERS: 'Customer/GetAll',
  GETCUSTOMERS: 'Customer',
  CREATECUSTOMER: 'Customer/Create',
  UPDATECUSTOMER: 'Customer',
  DELETECUSTOMER: 'Customer',
  GETCUSTOMERBYID: 'Customer',

  GETALLHOLIDAYENQUIRYS: 'HolidayEnquiry/GetAll',
  GETHOLIDAYENQUIRYS: 'HolidayEnquiry',
  CREATEHOLIDAYENQUIRY: 'HolidayEnquiry/Create',
  UPDATEHOLIDAYENQUIRY: 'HolidayEnquiry',
  DELETEHOLIDAYENQUIRY: 'HolidayEnquiry',
  GETHOLIDAYENQUIRYBYID: 'HolidayEnquiry',

  GETALLPACKAGEENQUIRYS: 'PackageEnquiry/GetAll',
  GETPACKAGEENQUIRYS: 'PackageEnquiry',
  CREATEPACKAGEENQUIRY: 'PackageEnquiry/Create',
  UPDATEPACKAGEENQUIRY: 'PackageEnquiry',
  DELETEPACKAGEENQUIRY: 'PackageEnquiry',
  GETPACKAGEENQUIRYBYID: 'PackageEnquiry',

  GETALLTOURS: 'Tour/GetAll',
  GETTOURS: 'Tour',
  CREATETOUR: 'Tour/Create',
  UPDATETOUR: 'Tour',
  DELETETOUR: 'Tour',
  GETTOURBYID: 'Tour',
  GETTOURSBYDESTINATIONID: 'Tour/GetByDestinationId',
  GETTOURSBYDESTINATION: 'Tour/GetByDestination',

  GETALLHOLIDAYITINERARY: 'HolidayItinerary',
  GETHOLIDAYITINERARY: 'HolidayItinerary',
  GETHOLIDAYITINERARYBYPACKAGEID: 'HolidayItinerary/GetByPackageId',
  CREATEHOLIDAYITINERARY: 'HolidayItinerary/Create',
  UPDATEHOLIDAYITINERARY: 'HolidayItinerary',
  DELETEHOLIDAYITINERARY: 'HolidayItinerary',
  GETHOLIDAYITINERARYBYID: 'HolidayItinerary',


  PAYMENTREQUESTORDER: 'Payment/Process',
  PAYMENTCOMPLETEORDER: 'Payment/ProcessCompleteOrder',

  CREATETRANSACTION: 'Transaction/Create',

  SEARCHFLIGHTS: "Flight/Search",
  REVIEWFLIGHT: "Flight/Review",
  BOOKFLIGHT: "Flight/Book",
  GETUSERFLIGHTS: "User/GetFlightBookings",
  GETAGENTFLIGHTS: "Flight/GetByAgentId",
  GETFLIGHTBOOKINGBYID: 'Flight/GetById',

  SEARCHHOTELS: "Hotel/Search",
  HOTELDETAILS: "Hotel/Details",
  HOTELREVIEW: "Hotel/Review",
  HOTELBOOK: "Hotel/Book",
  GETUSERHOTELS: "User/GetHotelBookings",
  GETHOTELBYID: "Hotel/GetById",

  GETUMRAH: "UMRAH",
  GETUMRAHBYID: "UMRAH",

  GETUMRAHENQUIRYS: 'UmrahEnquiry',
  CREATEUMRAHENQUIRY: 'UmrahEnquiry/Create',
  UPDATEUMRAHENQUIRY: 'UmrahEnquiry',

  GETCURRENCIES: 'Currency'
}
import React from 'react';
import { Link } from 'react-router-dom';

const TopHeader = () => {
    return (
        <div className="small-header">
            <div style={{textAlign:"left"}}>
                <div className="header-socials">
                    <ul>
                        <li><Link to={"https://www.facebook.com/61568163874859"} target="_blank" style={{ color: "#fff" }}><i className="fa-brands fa-facebook" /></Link></li>
                        <li><Link to={"https://twitter.com/babaairtravels"} target="_blank" style={{ color: "#fff" }}><i className="fa-brands fa-twitter" /></Link></li>
                        <li><Link to={"https://instagram.com/babaair"} target="_blank" style={{ color: "#fff" }}><i className="fa-brands fa-instagram" /></Link></li>
                    </ul>
                </div>
            </div>
            <div className="right-content">
            <div className="header-socials">
                    <ul>                        
                        <li><i className="fa fa-phone" /></li>
                        <li>India</li>
                        <li>+91 77656344, 9849787154 </li>
                        <li><i className="fa fa-phone" /></li>
                        <li>Bahrain</li>
                        <li>+973 77941249, 32271249</li>
                        <li><i className="fa fa-phone" /></li>
                        <li>Saudi Arabia</li>
                        <li>+966 503729667</li>
                    </ul>
                </div>
            </div>
        </div>
    );
};
export default TopHeader;
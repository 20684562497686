import { useEffect, useRef, useState } from "react";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Currency from "../Authentication/Currency";
import airports from '../Data/AirportList.json';
import PromoCodes from "../Master/PromoCode";
export default function SearchFlight() {
    const navigate = useNavigate();
    const [travelerDisplay, setTravelerDisplay] = useState(false);
    const [isOneWay, setIsOneWay] = useState(true);
    const [isDomestic, setIsDomestic] = useState('DOMESTIC');
    const [preffered, setPreferred] = useState("REGULAR");
    const [journeyType, setJourneyType] = useState("ONEWAY");
    const [locationFrom, setLocationFrom] = useState('HYD');
    const [locationTo, setLocationTo] = useState('BOM');
    const [displayFrom, setDisplayFrom] = useState('Hyderabad(HYD)');
    const [displayTo, setDisplayTo] = useState('Mumbai(BOM)');
    const [cityFrom, setCityFrom] = useState('Hyderabad');
    const [cityTo, setCityTo] = useState('Mumbai');
    const [departureDate, setDepartureDate] = useState(new Date());
    const [returnDate, setReturnDate] = useState(new Date());
    const [adults, setAdults] = useState(1);
    const [kids, setKids] = useState(0);
    const [infants, setInfants] = useState(0);
    const [cabinClass, setCabinClass] = useState("ECONOMY");
    const [errors, setErrors] = useState({});
    const [from, setFrom] = useState('Hyderabad, India');
    const [to, setTo] = useState('Mumbai, India');
    const [userId, setUserId] = useState("00000000-0000-0000-0000-000000000000");
    const DelhiMumbaiFlight = (e) => {
        e.preventDefault();
        const searchQuery = {
            "journeyType": 'ONEWAY',
            "locationFrom": 'DEL',
            "locationTo": 'BOM',
            "displayFrom": 'Delhi Indira Gandhi Intl, Delhi(DEL)',
            "displayTo": 'Chhatrapati Shivaji, Mumbai(BOM)',
            "adults": adults,
            "kids": kids,
            "infants": infants,
            "cabinClass": cabinClass,
            "departureDate": moment(departureDate).format('YYYY-MM-DD'),
            "returnDate": moment(returnDate).format('YYYY-MM-DD'),
            "cityFrom": 'Delhi',
            "cityTo": 'Mumbai',
            "userId": userId,
            "from": 'Delhi, India',
            "to": 'Mumbai, India',
            "isOneWay": true,
            "isDomestic": isDomestic
        }
        navigate('/oneway-flights/' + adults + "/" + kids + "/" + infants + "/" + isDomestic, { state: { searchQuery } });
    }
    const DelhiBangaloreFlight = (e) => {
        e.preventDefault();
        const searchQuery = {
            "journeyType": 'ONEWAY',
            "locationFrom": 'DEL',
            "locationTo": 'BLR',
            "displayFrom": 'Delhi Indira Gandhi Intl, Delhi(DEL)',
            "displayTo": 'Bengaluru Intl Arpt, Bengaluru(BLR)',
            "adults": adults,
            "kids": kids,
            "infants": infants,
            "cabinClass": cabinClass,
            "departureDate": moment(departureDate).format('YYYY-MM-DD'),
            "returnDate": moment(returnDate).format('YYYY-MM-DD'),
            "cityFrom": 'Delhi',
            "cityTo": 'Bengaluru',
            "userId": userId,
            "from": 'Delhi, India',
            "to": 'Bengaluru, India',
            "isOneWay": true,
            "isDomestic": isDomestic,
            "requestedBy":"CUSTOMER",
        }
        navigate('/oneway-flights/' + adults + "/" + kids + "/" + infants + "/" + isDomestic, { state: { searchQuery } });
    }
    const DelhiHyderabadFlight = (e) => {
        e.preventDefault();
        const searchQuery = {
            "journeyType": 'ONEWAY',
            "locationFrom": 'DEL',
            "locationTo": 'HYD',
            "displayFrom": 'Delhi Indira Gandhi Intl, Delhi(DEL)',
            "displayTo": 'Shamshabad Rajiv Gandhi Intl Arpt, Hyderabad(HYD)',
            "adults": adults,
            "kids": kids,
            "infants": infants,
            "cabinClass": cabinClass,
            "departureDate": moment(departureDate).format('YYYY-MM-DD'),
            "returnDate": moment(returnDate).format('YYYY-MM-DD'),
            "cityFrom": 'Delhi',
            "cityTo": 'Hyderabad',
            "userId": userId,
            "from": 'Delhi, India',
            "to": 'Hyderabad, India',
            "isOneWay": true,
            "isDomestic": isDomestic,
            "requestedBy":"CUSTOMER",
        }
        navigate('/oneway-flights/' + adults + "/" + kids + "/" + infants + "/" + isDomestic, { state: { searchQuery } });
    }
    const AhmedabadMumbaiFlight = (e) => {
        e.preventDefault();
        const searchQuery = {
            "journeyType": 'ONEWAY',
            "locationFrom": 'AMD',
            "locationTo": 'BOM',
            "displayFrom": 'Sardar Vallabh Bhai Patel Intl Arpt, Ahmedabad(AMD)',
            "displayTo": 'Chhatrapati Shivaji, Mumbai(BOM)',
            "adults": adults,
            "kids": kids,
            "infants": infants,
            "cabinClass": cabinClass,
            "departureDate": moment(departureDate).format('YYYY-MM-DD'),
            "returnDate": moment(returnDate).format('YYYY-MM-DD'),
            "cityFrom": 'Ahmedabad',
            "cityTo": 'Mumbai',
            "userId": userId,
            "from": 'Ahmedabad, India',
            "to": 'Mumbai, India',
            "isOneWay": true,
            "isDomestic": isDomestic,
            "requestedBy":"CUSTOMER",
        }
        navigate('/oneway-flights/' + adults + "/" + kids + "/" + infants + "/" + isDomestic, { state: { searchQuery } });
    }
    const MumbaiDubaiFlight = (e) => {
        e.preventDefault();
        const searchQuery = {
            "journeyType": 'ONEWAY',
            "locationFrom": 'BOM',
            "locationTo": 'DXB',
            "displayFrom": 'Chhatrapati Shivaji, Mumbai(BOM)',
            "displayTo": 'Dubai Intl Arpt, Dubai(DXB)',
            "adults": adults,
            "kids": kids,
            "infants": infants,
            "cabinClass": cabinClass,
            "departureDate": moment(departureDate).format('YYYY-MM-DD'),
            "returnDate": moment(returnDate).format('YYYY-MM-DD'),
            "cityFrom": 'Mumbai',
            "cityTo": 'Dubai',
            "userId": userId,
            "from": 'Mumbai, India',
            "to": 'Dubai, UAE',
            "isOneWay": true,
            "isDomestic": 'INTERNATIONAL',
            "requestedBy":"CUSTOMER",
        }
        navigate('/oneway-flights/' + adults + "/" + kids + "/" + infants + "/" + isDomestic, { state: { searchQuery } });
    }
    const DelhiTorontoFlight = (e) => {
        e.preventDefault();
        const searchQuery = {
            "journeyType": 'ONEWAY',
            "locationFrom": 'DEL',
            "locationTo": 'YYZ',
            "displayFrom": 'Delhi Indira Gandhi Intl, Delhi(DEL)',
            "displayTo": 'Lester B Pearson Intl, Toronto(YYZ)',
            "adults": adults,
            "kids": kids,
            "infants": infants,
            "cabinClass": cabinClass,
            "departureDate": moment(departureDate).format('YYYY-MM-DD'),
            "returnDate": moment(returnDate).format('YYYY-MM-DD'),
            "cityFrom": 'Delhi',
            "cityTo": 'Toronto',
            "userId": userId,
            "from": 'Delhi, India',
            "to": 'Toronto, USA',
            "isOneWay": true,
            "isDomestic": 'INTERNATIONAL',
            "requestedBy":"CUSTOMER",
        }
        navigate('/oneway-flights/' + adults + "/" + kids + "/" + infants + "/" + isDomestic, { state: { searchQuery } });
    }
    const ChennaiSingaporeFlight = (e) => {
        e.preventDefault();
        const searchQuery = {
            "journeyType": 'ONEWAY',
            "locationFrom": 'MAA',
            "locationTo": 'SIN',
            "displayFrom": 'Chennai Arpt, Chennai(MAA)',
            "displayTo": 'Changi Intl Arpt, Singapore(SIN)',
            "adults": adults,
            "kids": kids,
            "infants": infants,
            "cabinClass": cabinClass,
            "departureDate": moment(departureDate).format('YYYY-MM-DD'),
            "returnDate": moment(returnDate).format('YYYY-MM-DD'),
            "cityFrom": 'Chennai',
            "cityTo": 'Singapore',
            "userId": userId,
            "from": 'Chennai, India',
            "to": 'Singapore, Singapore',
            "isOneWay": true,
            "isDomestic": 'INTERNATIONAL',
            "requestedBy":"CUSTOMER",
        }
        navigate('/oneway-flights/' + adults + "/" + kids + "/" + infants + "/" + isDomestic, { state: { searchQuery } });
    }
    const DelhiLondonFlight = (e) => {
        e.preventDefault();
        const searchQuery = {
            "journeyType": 'ONEWAY',
            "locationFrom": 'DEL',
            "locationTo": 'LHR',
            "displayFrom": 'Delhi Indira Gandhi Intl, Delhi(DEL)',
            "displayTo": 'Heathrow, London(LHR)',
            "adults": adults,
            "kids": kids,
            "infants": infants,
            "cabinClass": cabinClass,
            "departureDate": moment(departureDate).format('YYYY-MM-DD'),
            "returnDate": moment(returnDate).format('YYYY-MM-DD'),
            "cityFrom": 'Delhi',
            "cityTo": 'London',
            "userId": userId,
            "from": 'Delhi, India',
            "to": 'London, London',
            "isOneWay": true,
            "isDomestic": 'INTERNATIONAL',
            "requestedBy":"CUSTOMER",
        }
        navigate('/oneway-flights/' + adults + "/" + kids + "/" + infants + "/" + isDomestic, { state: { searchQuery } });
    }
    const validate = () => {
        let temp = {};
        temp.displayFrom = displayFrom === "" ? false : true;
        temp.displayTo = displayTo === "" ? false : true;
        setErrors(temp);
        return Object.values(temp).every((x) => x === true);
    };
    const handleJourneyType = (e) => {
        if (e.target.value === "ROUNDTRIP") {
            setIsOneWay(false)
        }
        else {
            setIsOneWay(true)
        }
        setJourneyType(e.target.value)
    }
    const handleSearch = (e) => {
        e.preventDefault();
        if (validate()) {
            const searchQuery = {
                "journeyType": journeyType,
                "locationFrom": locationFrom,
                "locationTo": locationTo,
                "displayFrom": displayFrom,
                "displayTo": displayTo,
                "adults": adults,
                "kids": kids,
                "infants": infants,
                "cabinClass": cabinClass,
                "departureDate": moment(departureDate).format('YYYY-MM-DD'),
                "returnDate": moment(returnDate).format('YYYY-MM-DD'),
                "cityFrom": cityFrom,
                "cityTo": cityTo,
                "userId": userId,
                "from": from,
                "to": to,
                "isOneWay": isOneWay,
                "isDomestic": isDomestic,
                "requestedBy":"CUSTOMER",
            }
            if (isOneWay) {
                navigate('/oneway-flights/' + adults + "/" + kids + "/" + infants + "/" + isDomestic, { state: { searchQuery } });
            }
            else {
                if (isDomestic === "INTERNATIONAL") {
                    navigate('/oneway-flights/' + adults + "/" + kids + "/" + infants + "/" + isDomestic, { state: { searchQuery } });
                }
                else {
                    navigate('/roundtrip-flights/' + adults + "/" + kids + "/" + infants + "/" + isDomestic, { state: { searchQuery } });
                }
            }
        }
    }
    const handleDisplayClick = () => {
        setTravelerDisplay(travelerDisplay => !travelerDisplay);
    }
    const handleClassType = (e) => {
        setCabinClass(e.target.value)
    }
    const handlePrefferedType = (e) => {
        setPreferred(e.target.value)
    }
    const handleIncreaseAdults = (e) => {
        setAdults(parseFloat(adults + 1))
    }
    const handleDecreaseAdults = (e) => {
        if (adults !== 1) {
            setAdults(parseFloat(adults - 1))
        }
    }
    const handleIncreaseKids = (e) => {
        setKids(parseFloat(kids + 1))
    }
    const handleDecreaseKids = (e) => {
        if (kids !== 0) {
            setKids(parseFloat(kids - 1))
        }
    }
    const handleIncreaseInfants = (e) => {
        setInfants(parseFloat(infants + 1))
    }
    const handleDecreaseInfants = (e) => {
        if (infants !== 0) {
            setInfants(parseFloat(infants - 1))
        }
    }
    const handleDepartureDate = (date) => {
        if (date !== null) {
            setDepartureDate(date)
            addDays(date)
        }
    }
    const handleReturnDate = (date) => {
        if (date !== null) {
            setReturnDate(date)
            addDays(date)
        }
    }
    const addDays = (departureDate) => {
        if (departureDate === undefined) {
            departureDate = new Date();
        }
        const newDate = new Date(departureDate.getTime());
        setReturnDate(newDate.setDate(departureDate.getDate()));
    }
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken"),
        }
    }
    const [departureResults, setDepartureResults] = useState([]);
    const [returnResults, setReturnResults] = useState([]);
    const inputDRef = useRef(null);
    const inputRRef = useRef(null);
    const handleDepartureAirportSearch = (e) => {
        const value = e.target.value;
        setDisplayFrom(value);
        if (value.length > 0) {
            const filteredAirports = airports
                .filter(airport =>
                    airport.airportCode.toLowerCase().includes(value.toLowerCase())
                )
                .slice(0, 10);
            setDepartureResults(filteredAirports);
            if (filteredAirports.length === 0) {
                const filteredAirports1 = airports
                    .filter(airport =>
                        airport.cityName.toLowerCase().includes(value.toLowerCase())
                    )
                    .slice(0, 10);
                setDepartureResults(filteredAirports1);
            }
        } else {
            setDepartureResults([]);
        }
    };
    const handleDepartureAirportClick = (e) => {
        setReturnResults([]);
        let filteredAirports = airports.filter(function (p) {
            return p.display === true
        }).slice(0, 10);
        setDepartureResults(filteredAirports);
        inputDRef.current.select();
    };
    const handleDepartureSelect = (data) => {
        if (data.countryCode !== "IN") {
            setIsDomestic('INTERNATIONAL')
        }
        var DF = data.cityName + "(" + data.airportCode + ")";
        setDisplayFrom(DF);
        setLocationFrom(data.airportCode)
        setCityFrom(data.cityName);
        setFrom(data.cityName);
        setDepartureResults([]);
    }
    const handleReturnAirportSearch = (e) => {
        const value = e.target.value;
        setDisplayTo(value);
        if (value.length > 0) {
            const filteredAirports = airports
                .filter(airport =>
                    airport.airportCode.toLowerCase().includes(value.toLowerCase())
                )
                .slice(0, 10);
            setReturnResults(filteredAirports);
            if (filteredAirports.length === 0) {
                const filteredAirports1 = airports
                    .filter(airport =>
                        airport.cityName.toLowerCase().includes(value.toLowerCase())
                    )
                    .slice(0, 10);
                setReturnResults(filteredAirports1);
            }
        } else {
            setReturnResults([]);
        }
    };
    const handleReturnSelect = (data) => {
        var DF = data.cityName + "(" + data.airportCode + ")";
        if (data.countryCode !== "IN") {
            setIsDomestic('INTERNATIONAL')
        }
        setDisplayTo(DF);
        setLocationTo(data.airportCode)
        setCityTo(data.cityName);
        setTo(data.cityName);
        setReturnResults([]);
    }
    const handleReturnAirportClick = (e) => {
        setDepartureResults([]);
        let filteredAirports = airports.filter(function (p) {
            return p.display === true
        }).slice(0, 10);
        setReturnResults(filteredAirports);
        inputRRef.current.select();
    };
    useEffect(() => {
        if (localStorage.getItem("userId") !== null) {
            setUserId(localStorage.getItem("userId"))
        }
        addDays(departureDate);
    }, [])
    return (
        <div id="main-wrapper">
            <Header></Header><div className="clearfix"></div>
            <div className="image-cover hero-header" style={{ background: 'url(/assets/img/2151022224.jpg)', maxHeight:"50px", padding:"0px" }}>
                <div className="m-3">
                    {/* Search Form */}
                    <div className="searchableForm bg-white rounded-3 py-3 px-4">
                        <div className="row justify-content-between align-items-center g-4">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <div className="flx-start mb-sm-0 mb-2">
                                <div className="form-check form-check-inline">
                                        <input style={{ border: "1px solid" }} className="form-check-input" type="radio" name="trip" id="oneway" onChange={e => handleJourneyType(e)} value="ONEWAY" defaultChecked/>
                                        <label className="form-check-label text-dark" htmlFor="oneway">One Way</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input style={{ border: "1px solid" }} className="form-check-input" type="radio" name="trip" id="roundtrip" onChange={e => handleJourneyType(e)} value="ROUNDTRIP" />
                                        <label className="form-check-label text-dark" htmlFor="return">Return</label>
                                    </div>                                    
                                </div>
                            </div>
                            <div className="col-xl-12 col-lg-12 col-md-12">
                                <div className="row gy-3 gx-lg-2 gx-3">
                                    <div className="col-xl-2 col-lg-6 col-md-6 col-sm-6 position-relative">
                                        <div className="form-group hdd-arrow mb-0">
                                            <h6 className="text-dark">FROM</h6>
                                            <input ref={inputDRef} className="form-control searchTextbox" type="text" placeholder="Search for an airport" value={displayFrom} onChange={handleDepartureAirportSearch} onClick={handleDepartureAirportClick} onFocus={handleDepartureAirportClick} />
                                            <span className="icon-inside">
                                                <svg xmlns="http://www.w3.org/2000/svg" width={20} height={17} fill="#808080" viewBox="0 0 20 17"><path d="M1.376 16.498v-1h17v1h-17zm1.575-5.075L.676 7.498l.85-.2 1.8 1.55 5.4-1.45-4.05-6.85L5.75.223l6.85 6.125 5.225-1.4c.383-.1.73-.03 1.038.212.308.242.462.554.462.938 0 .266-.075.5-.225.7-.15.2-.35.333-.6.4L2.95 11.423z" /></svg>
                                            </span>
                                            {errors.displayFrom === false ? (<div className="validationerror">Please select departure </div>) : ('')}
                                            {departureResults.length > 0 && (
                                                <ul className="locationListVideo scrollable-list" style={{ minWidth: "350px" }}>
                                                    {departureResults.map((airport) => (
                                                        <li className="locationListVideoItem" key={airport.airportCode} onClick={() => handleDepartureSelect(airport)}>
                                                            <div className="item-name">{airport.cityName} ({airport.airportCode})</div>
                                                            <div className="item-details">
                                                                <span className="item-code-date">{airport.airportName}</span>
                                                                <span className="item-designation">{airport.countryName}</span>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-auto px-1 d-flex align-items-center justify-content-center">
                                        {/* SVG Icon Between the Textboxes */}
                                        <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="none" viewBox="0 0 32 32" className="c-pointer">
                                            <rect width={32} height={32} fill="#fff" rx={16} />
                                            <g stroke="#3366CC" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" clipPath="url(#clip0_160_1650)">
                                                <path d="M24.167 14.833H7.833M7.833 14.833L13.667 9M7.833 18.334h16.334M24.167 18.333l-5.834 5.834" />
                                                <circle cx={16} cy={16} r="13.375" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_160_1650">
                                                    <path fill="#fff" d="M0 0H28V28H0z" transform="translate(2 2)" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </div>
                                    <div className="col-xl-2 col-lg-6 col-md-6 col-sm-6">
                                        <div className="form-groupp hdd-arrow mb-0">
                                            <h6 className="text-dark">TO</h6>
                                            <div className="position-relative">
                                                <input ref={inputRRef} className="form-control" type="text" placeholder="Search for an airport" value={displayTo} onChange={handleReturnAirportSearch} onClick={handleReturnAirportClick} onFocus={handleReturnAirportClick} />
                                                <span className="icon-inside">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="#808080" viewBox="0 0 24 24"><mask id="mask0_160_1644" style={{ maskType: 'alpha' }} width={24} height={24} x={0} y={0} maskUnits="userSpaceOnUse"><path fill="#808080" d="M0 0H24V24H0z" /></mask><g mask="url(#mask0_160_1644)"><path d="M3.5 20.5v-1h17v1h-17zm15.475-5.1L3.5 11.125V6.7l.8.225L5 9.05l5.5 1.55V2.65l1.125.275 2.75 8.75 5.25 1.475c.267.067.48.209.638.425.158.217.237.459.237.725 0 .384-.162.692-.487.925-.325.234-.671.292-1.038.175z" /></g></svg>
                                                </span>
                                                {returnResults.length > 0 && (
                                                    <ul className="locationListVideo scrollable-list" style={{ minWidth: "350px" }}>
                                                        {returnResults.map((airport) => (
                                                            <li className="locationListVideoItem" key={airport.airportCode} onClick={() => handleReturnSelect(airport)}>
                                                                <div className="item-name">{airport.cityName} ({airport.airportCode})</div>
                                                                <div className="item-details">
                                                                    <span className="item-code-date">{airport.airportName}</span>
                                                                    <span className="item-designation">{airport.countryName}</span>
                                                                </div>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </div>
                                            {errors.displayTo === false ? (<div className="validationerror">Please select arrival </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-xl-2 col-lg-6 col-md-6 col-sm-6 position-relative">
                                        <h6 className="text-dark">DEPATURE DATE</h6>
                                        <div className="position-relative">
                                            <ReactDatePicker autoComplete="false" className="form-control" wrapperClassName="datePicker" name="departureDate" selected={departureDate} onChange={(date) => handleDepartureDate(date)} dateFormat="EEEE, dd MMM yyyy" minDate={new Date()} />
                                            <span className="icon-inside"><i className="far fa-calendar-alt" /></span>
                                            {errors.departureDate === false ? (<div className="validationerror">Please select departureDate </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-xl-2 col-lg-6 col-md-6 col-sm-6 position-relative">
                                        <h6 className="text-dark">RETURN DATE</h6>
                                        <div className="position-relative">
                                            {isOneWay === true ?
                                                <ReactDatePicker disabled autoComplete="false" className="form-control" wrapperClassName="datePicker" name="returnDate" placeholderText="Return Date" />
                                                :
                                                <ReactDatePicker autoComplete="false" className="form-control" wrapperClassName="datePicker" name="returnDate" selected={returnDate} onChange={(date) => { handleReturnDate(date) }} dateFormat="EEEE, dd MMM yyyy" minDate={departureDate} />
                                            }
                                            <span className="icon-inside"><i className="far fa-calendar-alt" /></span>
                                            {errors.returnDate === false ? (<div className="validationerror">Please select returnDate </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-xl-2 col-lg-6 col-md-6 col-sm-6 position-relative">
                                        <h6 className="text-dark">TRAVELLERS</h6>
                                        <div className="form-group mb-0">
                                            <div className="booking-form__input guests-input mixer-auto" style={{ top: "60px !important" }}>
                                                <button name="guests-btn" id="guests-input-btn" className={travelerDisplay === true ? "open" : ""} onClick={handleDisplayClick}><i className="fas fa-users me-2" />{adults + " Adults, " + kids + " Kids, " + infants + " Infants"}</button>
                                                <div className={travelerDisplay === true ? "guests-input__options open" : "guests-input__options"} id="guests-input-options">
                                                    <div>
                                                        <span className="guests-input__ctrl minus" id="adults-subs-btn" onClick={handleDecreaseAdults}><i className="fa-solid fa-minus" /></span>
                                                        <span className="guests-input__value"><span defaultValue={1} readOnly value={adults} name="adults">{adults}</span>Adults</span>
                                                        <span className="guests-input__ctrl plus" id="adults-add-btn" onClick={handleIncreaseAdults}><i className="fa-solid fa-plus" /></span>
                                                    </div>
                                                    <div>
                                                        <span className="guests-input__ctrl minus" id="children-subs-btn" onClick={handleDecreaseKids}><i className="fa-solid fa-minus" /></span>
                                                        <span className="guests-input__value"><span defaultValue={0} readOnly value={kids} name="kids">{kids}</span>Kids</span>
                                                        <span className="guests-input__ctrl plus" id="children-add-btn" onClick={handleIncreaseKids}><i className="fa-solid fa-plus" /></span>
                                                    </div>
                                                    <div>
                                                        <span className="guests-input__ctrl minus" id="room-subs-btn" onClick={handleDecreaseInfants}><i className="fa-solid fa-minus" /></span>
                                                        <span className="guests-input__value"><span defaultValue={0} readOnly value={infants} name="infants">{infants}</span>Infants</span>
                                                        <span className="guests-input__ctrl plus" id="room-add-btn" onClick={handleIncreaseInfants}><i className="fa-solid fa-plus" /></span>
                                                    </div>
                                                        <ul className="flx-start mb-sm-0 m-3" style={{ textAlign: "start" }}>
                                                            <li className="mb-2">
                                                                <input style={{ border: "1px solid", marginRight: "5px" }} className="form-check-input text-bold" type="radio" name="journeytype" id="ECONOMY" onChange={e => handleClassType(e)} value="ECONOMY" checked={cabinClass === "ECONOMY" ? true : false} />
                                                                <label className="form-check-label" htmlFor="return">ECONOMY</label>
                                                            </li>
                                                            <li className="mb-2">
                                                                <input style={{ border: "1px solid", marginRight: "5px" }} className="form-check-input text-bold" type="radio" name="journeytype" id="BUSINESS" onChange={e => handleClassType(e)} value="BUSINESS" checked={cabinClass === "BUSINESS" ? true : false} />
                                                                <label className="form-check-label" htmlFor="return">BUSINESS</label>
                                                            </li>
                                                            <li>
                                                                <input style={{ border: "1px solid", marginRight: "5px" }} className="form-check-input text-bold" type="radio" name="journeytype" id="FIRST" onChange={e => handleClassType(e)} value="FIRST" checked={cabinClass === "FIRST" ? true : false} />
                                                                <label className="form-check-label" htmlFor="return">FIRST</label>
                                                            </li>
                                                        </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-auto px-1" style={{ paddingTop: "28px" }}>
                                        <button type="button" onClick={e => handleSearch(e)} className="btn btn-danger full-width fw-medium"><i className="fa-solid fa-magnifying-glass me-2"></i>SEARCH</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 position-relative">
                                <div className="flx-start mb-sm-0 mb-2">
                                    <div className="form-check form-check-inline">
                                        <input style={{ border: "1px solid" }} className="form-check-input" type="radio" name="preferred" id="REGULAR" onChange={e => handlePrefferedType(e)} value="REGULAR" checked={preffered === "REGULAR" ? true : false} />
                                        <label className="form-check-label" htmlFor="return">Regular</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input style={{ border: "1px solid" }} className="form-check-input" type="radio" name="preferred" id="STUDENT" onChange={e => handlePrefferedType(e)} value="STUDENT" checked={preffered === "STUDENT" ? true : false} />
                                        <label className="form-check-label" htmlFor="oneway">Student</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input style={{ border: "1px solid" }} className="form-check-input" type="radio" name="preffered" id="SENIOR_CITIZEN" onChange={e => handlePrefferedType(e)} value="SENIOR_CITIZEN" checked={preffered === "SENIOR_CITIZEN" ? true : false} />
                                        <label className="form-check-label" htmlFor="oneway">Senior Citizen</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* </row> */}
                </div>
            </div>
            <PromoCodes></PromoCodes>
            <section className="container">
                <div className="row g-4">
                    <div className="col-lg-6">
                        <div className="bg-white shadow-md rounded p-4">
                            <h3 className="text-6 mb-4">Popular Domestic Routes</h3>
                            <hr className="mx-n4" />
                            <p className="d-flex align-items-center">Delhi to Mumbai Flights <button onClick={e => DelhiMumbaiFlight(e)} type="button" className="btn btn-sm btn-danger shadow-none ms-auto"><i className="fas fa-search d-block d-sm-none" /> <span className="d-none d-sm-block">Search Flights</span></button></p>
                            <hr />
                            <p className="d-flex align-items-center">Delhi to Bangalore Flights <button onClick={e => DelhiBangaloreFlight(e)} type="button" className="btn btn-sm btn-danger shadow-none ms-auto"><i className="fas fa-search d-block d-sm-none" /> <span className="d-none d-sm-block">Search Flights</span></button></p>
                            <hr />
                            <p className="d-flex align-items-center">Delhi to Hyderabad Flights <button onClick={e => DelhiHyderabadFlight(e)} type="button" className="btn btn-sm btn-danger shadow-none ms-auto"><i className="fas fa-search d-block d-sm-none" /> <span className="d-none d-sm-block">Search Flights</span></button></p>
                            <hr />
                            <p className="d-flex align-items-center">Ahmedabad to Mumbai Flights <button onClick={e => AhmedabadMumbaiFlight(e)} type="button" className="btn btn-sm btn-danger shadow-none ms-auto"><i className="fas fa-search d-block d-sm-none" /> <span className="d-none d-sm-block">Search Flights</span></button></p>
                            <hr />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="bg-white shadow-md rounded p-4">
                            <h3 className="text-6 mb-4">Popular International Routes</h3>
                            <hr className="mx-n4" />
                            <p className="d-flex align-items-center">Mumbai to Dubai Flights <button onClick={e => MumbaiDubaiFlight(e)} type="button" className="btn btn-sm btn-danger shadow-none ms-auto"><i className="fas fa-search d-block d-sm-none" /> <span className="d-none d-sm-block">Search Flights</span></button></p>
                            <hr />
                            <p className="d-flex align-items-center">Delhi to Toronto Flights <button onClick={e => DelhiTorontoFlight(e)} type="button" className="btn btn-sm btn-danger shadow-none ms-auto"><i className="fas fa-search d-block d-sm-none" /> <span className="d-none d-sm-block">Search Flights</span></button></p>
                            <hr />
                            <p className="d-flex align-items-center">Chennai to Singapore Flights <button onClick={e => ChennaiSingaporeFlight(e)} type="button" className="btn btn-sm btn-danger shadow-none ms-auto"><i className="fas fa-search d-block d-sm-none" /> <span className="d-none d-sm-block">Search Flights</span></button></p>
                            <hr />
                            <p className="d-flex align-items-center">Delhi to London Flights <button onClick={e => DelhiLondonFlight(e)} type="button" className="btn btn-sm btn-danger shadow-none ms-auto"><i className="fas fa-search d-block d-sm-none" /> <span className="d-none d-sm-block">Search Flights</span></button></p>
                            <hr />
                        </div>
                    </div>
                </div>
            </section>
            <Footer></Footer>
            <Currency></Currency>
        </div>
    );
}